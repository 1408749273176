import React, { Component } from 'react';
import { Subsection } from './Subsection';
import { Logo } from './Logo';
import { Grid, Column, Row } from "react-awesome-grid";
import MediaQuery from 'react-responsive';

export class OverviewSection extends Component {
    render() {
        return (
            <div className="overview section-background">
                <Subsection imageUrl='OverviewBackground.jpg'>
                    <Grid>
                        <Column width="*">
                            <Row height="auto">
                                <Column width="auto">
                                    <Logo size="100" />
                                </Column>
                                <Column width="*" verticalContentAlignment="center" horizontalContentAlignment="center">
                                    <MediaQuery minWidth={1001}>
                                        <div>
                                            <h1>Alexander Kvenvolden</h1>
                                        </div>
                                    </MediaQuery>
                                    <MediaQuery maxWidth={1000}>
                                        <Row height="*" horizontalContentAlignment="center" verticalContentAlignment="bottom">
                                            <h1>Alexander</h1>
                                        </Row>
                                        <Row height="*" horizontalContentAlignment="center" verticalContentAlignment="top">
                                            <h1>Kvenvolden</h1>
                                        </Row>
                                    </MediaQuery>
                                </Column>
                                <MediaQuery maxWidth={800}>
                                    <Column width="auto" verticalContentAlignment="center">
                                        <img src="Headshot.jpg" alt="Head shot" style={{ width: "100px", marginLeft: "1em" }} />
                                    </Column>
                                </MediaQuery>
                            </Row>
                            <Row height="auto" horizontalContentAlignment="center">
                                <p>
                                    Hi! I am a Seattle-area software developer, husband, and dad. I like to write apps in my free time. Here you will find an interactive resume of my work, education, and projects. I'm currently looking for work, and am open to a wide variety of software engineering positions.
                                      </p>
                            </Row>
                            <Row height="auto" horizontalContentAlignment="space-around">
                                <button className="btn experience" onClick={this.experienceClicked.bind(this)} > Experience</button>
                                <button className="btn education" onClick={this.educationClicked.bind(this)}>Education</button>
                                <button className="btn projects" onClick={this.projectsClicked.bind(this)}>Projects</button>
                            </Row>
                        </Column>
                        <MediaQuery minWidth={801}>
                            <Column width="auto" verticalContentAlignment="center">
                                <img src="Headshot.jpg" alt="Head shot" style={{ width: "200px", marginLeft: "1em" }} />
                            </Column>
                        </MediaQuery>
                    </Grid>
                </Subsection>
            </div>);
    }

    experienceClicked() {
        if (this.props.onExperienceClicked)
            this.props.onExperienceClicked();
    }

    educationClicked() {
        if (this.props.onEducationClicked)
            this.props.onEducationClicked();
    }

    projectsClicked() {
        if (this.props.onProjectsClicked)
            this.props.onProjectsClicked();
    }
}