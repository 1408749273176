import React, { Component } from 'react';
import './FilterItem.css';

export class FilterItem extends Component {
    #handleExternalChange;

    constructor(props) {
        super(props);
        this.state = { isChecked: false, isPressed: false }
        if (this.props && this.props.table && this.props.item)
            this.state.isChecked = this.props.table.getFilter(this.props.item.value);
        this.handleExternalChange = function(value) {
            if (this.state.isChecked !== value) {
                this.setState({ ...this.state, isChecked: value });
            }
        };
    }

    componentDidMount() {
        if (this.props && this.props.table && this.props.item) {
            this.props.table.registerChangeListener(this, this.props.item.value, this.handleExternalChange);
        }
    }

    componentWillUnmount() {
        if (this.props && this.props.table && this.props.item) {
            this.props.table.unregisterChangeListener(this);
        }
    }

    toggleCheckboxChange = () => {
        //const { handleCheckboxChange, label } = this.props;

        var isChecked = !this.state.isChecked;
        this.setState({ ...this.state, isChecked: isChecked });

        if (this.props && this.props.table && this.props.item) {
            this.props.table.setFilter(this.props.item.value, isChecked);
        }

        //handleCheckboxChange(label);
    }

    getColors(colorName, isPressed) {
        switch (colorName.toLowerCase()) {
            case "red":
                return {
                    border: '#ff0000',
                    background: isPressed ? '#800000' : '#cc0000'
                };
            case "blue":
                return {
                    border: '#4f6fe3',
                    background: isPressed ? '#152d84' : '#224bdd'
                };
            case "cyan":
                return {
                    border: '#00e5e6',
                    background: isPressed ? '#006666' : '#00b2b3'
                };
            case "purple":
                return {
                    border: '#cc00cc',
                    background: isPressed ? '#5c0a5c' : '#a112a1'
                };
            case "green":
                return {
                    border: '#00b300',
                    background: isPressed ? '#004d00' : '#008000'
                };
            case "orange":
                return {
                    border: '#ff8c00',
                    background: isPressed ? '#995400' : '#e07b00'
                };
            case "yellow":
                return {
                    border: '#cccc00',
                    background: isPressed ? '#666600' : '#999900'
                };
            default:
                return {
                    border: colorName,
                    background: colorName
                };
        }
    }

    mouseDown() {
        this.setState({ ...this.state, isPressed: true });
    }

    mouseUp() {
        this.setState({ ...this.state, isPressed: false });
    }

    render() {
        const item = this.props ? this.props.item : { friendlyName: '', labelColor: '' };
        const label = item.friendlyName;

        const { isChecked, isPressed } = this.state;
        const colors = this.getColors(item.labelColor, isPressed);
        const borderColor = colors.border;

        const backgroundColor = isChecked ? 'white' : colors.background;
        const textColor = isChecked ? colors.background : 'white';

        return (<div style={{ display: "inline-block" }}
            onMouseDownCapture={this.mouseDown.bind(this)}
            onMouseUpCapture={this.mouseUp.bind(this)} >
            <label className="filter-item-label" style={{
                backgroundColor: backgroundColor,
                color: textColor,
                border: `2px solid ${borderColor}`
            }}>
                <input
                    type="checkbox"
                    value={label}
                    checked={isChecked}
                    onChange={this.toggleCheckboxChange}
                />
                {label}
            </label>
        </div>);
    }
}