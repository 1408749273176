import React, { Component } from 'react';
import { Container } from 'reactstrap';
import Markdown from 'react-remarkable';
import { FilterItem } from './FilterItem';
import MediaQuery from 'react-responsive';
import { Grid, Column, Row } from "react-awesome-grid";

export class Subsection extends Component {
    render() {
        var style = {
            backgroundSize: "cover",
            color: "#FFFFFF",
            backgroundColor: "#333333",
            position: "absolute",
            width: "100%",
            height: "100%",
            left: "0",
            right: "0",
            top: "0",
            bottom: "0",
            zIndex: "-2"
        };
        if (this.props.imageUrl !== undefined)
            style.backgroundImage = `url(${this.props.imageUrl})`;
        return (<div className="subsection">
            <div style={style} />
            <Container className="section">
                {this.props.children}
            </Container>
        </div>);
    }

    static renderSubsection(sectionItem, platformsTable, languagesTable, frameworksTable, itemType) {
        const platforms = (sectionItem.platforms && sectionItem.platforms.length > 0) ?
            sectionItem.platforms.map(p => (<FilterItem item={p} table={platformsTable} />))
            : null;
        const languages = (sectionItem.languages && sectionItem.languages.length > 0) ?
            sectionItem.languages.map(p => (<FilterItem item={p} table={languagesTable} />))
            : null;
        const frameworks = (sectionItem.frameworks && sectionItem.frameworks.length > 0) ?
            sectionItem.frameworks.map(p => (<FilterItem item={p} table={frameworksTable} />))
            : null;

        return (
            <Subsection imageUrl={sectionItem.imageUrl}>
                <Grid>
                    {
                        sectionItem.title ? (<Row height="auto"><h2>{sectionItem.title}</h2></Row>) : ''
                    }
                    {
                        sectionItem.subtitle ? (<Row height="auto"><h4>{sectionItem.subtitle}</h4></Row>) : ''
                    }
                    {
                        sectionItem.overview ? (<Row height="auto"><Markdown>{sectionItem.overview}</Markdown></Row>) : ''
                    }
                    {
                        platforms ? (
                            <>
                                <Row height="auto"><div><strong>Platforms</strong> used in this {itemType}:</div></Row>
                                <Row height="auto"><div className="horizontal-scroll">{platforms}</div></Row>
                            </>
                        ) : ''
                    }
                    {
                        languages ? (
                            <>
                                <Row height="auto"><div><strong>Languages</strong> used in this {itemType}:</div></Row>
                                <Row height="auto"><div className=" horizontal-scroll">{languages}</div></Row>
                            </>
                        ) : ''
                    }
                    {
                        frameworks ? (
                            <>
                                <Row height="auto"><div><strong>Frameworks</strong> used in this {itemType}:</div></Row>
                                <Row height="auto"><div className=" horizontal-scroll">{frameworks}</div></Row>
                            </>
                        ) : ''
                    }
                </Grid>
            </Subsection>
        );
    }

    static renderSubsectionsGrid(sectionItems, platformsTable, languagesTable, frameworksTable, widthThreshold, itemType) {
        const pairs = [];
        for (var i = 0; i < sectionItems.length; i += 2) {
            const first = sectionItems[i];
            const second = (i + 1) < sectionItems.length ? sectionItems[i + 1] : null;
            pairs.push({ first: first, second: second });
        }
        return (
            <Grid>
                <MediaQuery minWidth={widthThreshold}>
                        {
                            pairs.map(pair => (
                                <Row height="auto">
                                    <Column width="*" style={{ minWidth: "0px" }}>
                                        {
                                            Subsection.renderSubsection(pair.first, platformsTable, languagesTable, frameworksTable, itemType)
                                        }
                                    </Column>
                                    {
                                        pair.second ? (<Column width="*" style={{ minWidth: "0px" }}>
                                            {
                                                Subsection.renderSubsection(pair.second, platformsTable, languagesTable, frameworksTable, itemType)
                                            }
                                        </Column>) : ''
                                    }
                                </Row>
                            ))
                        }
                </MediaQuery>
                <MediaQuery maxWidth={widthThreshold - 1}>
                        {
                            sectionItems.map(item => (
                                <Row height="auto">
                                    {
                                        Subsection.renderSubsection(item, platformsTable, languagesTable, frameworksTable, itemType)
                                    }
                                </Row>
                            ))
                        }
                </MediaQuery>
            </Grid>);
    }
}