import React, { Component } from 'react';

export class Logo extends Component {
    render() {
        const size = this.props.size === undefined ? 200 : this.props.size;

        let layers = [(<path d="M278,104 L280,106 L280,212 Q284 213 284 215  L284,219 L285,220 L285,225 L320,260 L236,260 L273,225 L273,220 L274,219 L274,215 Q274 213 278 212"
            className="logo-foreground" />),
            (<circle cx="279" cy="260" r="7" className="logo-foreground" />)];

        if (this.props.variant === "experience")
            layers = [(<polygon className="logo-outline-foreground logo-foreground" points="266,143 310,143 298,168 278,168" />),
                (<polygon className="logo-outline-foreground" points="298,168 313,307 288,332 263,307 278,168" />),
                (<polygon className="logo-outline-foreground" points="298,168 325,269 311,290" />)];

        if (this.props.variant === "education")
            layers = [(<polygon points="179,209 254,184 329,209 254,235"
                className="logo-foreground" />),
            (<path className="logo-outline-foreground" d="M296,220 L296,252  Q254, 275 212,252 L212, 220" />),
            (<polygon fill="White" points="316,217 320,215.5 320,260.5 316,262"
                    className="logo-foreground" />)];

        if (this.props.variant === "projects")
            layers = [(<polygon points="200,180 328,180 328,200 200,200"
                className="logo-outline-foreground logo-foreground" />),
                (<polygon points="328,200 328,279 200,279 200,200"
                    className="logo-outline-foreground" />),
                (<path className="logo-outline-foreground" d="M242,256 L213,240 L242,224" />),
                (<path className="logo-outline-foreground" d="M284,256 L313,240 L284,224" />),
                (<path className="logo-outline-foreground" d="M251,272 275,207" />)];

        return (
            <svg width={size} height={size} viewBox="0 0 400 400">
                <polygon points="0,202 199.5,25 399,202 337,202 337,372 62,372 62,202"
                    className="logo-background"/>
                <polygon points="69,150 104,119 104,366 69,366"
                    className="logo-foreground"/>
                <polygon points="103.5,232.5 211,103 248,103 144.5,228.5 266,366 220,366"
                    className="logo-foreground" />
                {
                    layers
                }
            </svg>
        );
    }
}
