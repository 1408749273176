import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Subsection } from './Subsection';
import { OverviewSection } from './OverviewSection';
import { ExperienceSection } from './ExperienceSection';
import { EducationSection } from './EducationSection';
import { ProjectsSection } from './ProjectsSection';
import './Home.css'

export class Home extends Component {
    static displayName = Home.name;



    render() {

      return (
          <div>
              <OverviewSection
                  onExperienceClicked={this.experienceClicked.bind(this)}
                  onEducationClicked={this.educationClicked.bind(this)}
                  onProjectsClicked={this.projectsClicked.bind(this)} />
              <ExperienceSection ref={ref => this.experienceRef = ref}/>
              <EducationSection ref={ref => this.educationRef = ref}/>
              <ProjectsSection ref={ref => this.projectsRef = ref}/>
      </div>
        );
    }

    experienceClicked() {
        if (this.experienceRef) {
            var dom = ReactDOM.findDOMNode(this.experienceRef)
            if (dom)
                window.scrollTo(0, dom.offsetTop);
        }
    }

    educationClicked() {
        if (this.educationRef) {
            var dom = ReactDOM.findDOMNode(this.educationRef)
            if (dom)
                window.scrollTo(0, dom.offsetTop);
        }
    }

    projectsClicked() {
        if (this.projectsRef) {
            var dom = ReactDOM.findDOMNode(this.projectsRef)
            if (dom)
                window.scrollTo(0, dom.offsetTop);
        }
    }
}
