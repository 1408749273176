import React, { Component } from 'react';
import { Subsection } from './Subsection';
import { Logo } from './Logo';
import { Grid, Column, Row } from "react-awesome-grid";
import { FilterItem } from './FilterItem';
import { FilterTable } from './FilterTable';

export class ProjectsSection extends Component {
    #platformFilterTable
    #languageFilterTable
    #frameworkFilterTable
    #onPlatformsFilterChanged
    #onLanguagesFilterChanged
    #onFrameworksFilterChanged

    #myRef;

    constructor(props) {
        super(props);
        this.onPlatformsFilterChanged = function (filter) {
            this.setState({ ...this.state, platformsFilter: filter });
        }
        this.onLanguagesFilterChanged = function (filter) {
            this.setState({ ...this.state, languagesFilter: filter });
        }
        this.onFrameworksFilterChanged = function (filter) {
            this.setState({ ...this.state, frameworksFilter: filter });
        }

        this.platformFilterTable = new FilterTable();
        this.languageFilterTable = new FilterTable();
        this.frameworkFilterTable = new FilterTable();
        this.platformFilterTable.registerChangeListener(
            this, null, this.onPlatformsFilterChanged);
        this.languageFilterTable.registerChangeListener(
            this, null, this.onLanguagesFilterChanged);
        this.frameworkFilterTable.registerChangeListener(
            this, null, this.onFrameworksFilterChanged);
        this.state = {
            platforms: [],
            languages: [],
            frameworks: [],
            platformsFilter: "",
            languagesFilter: "",
            frameworksFilter: "",
            items: [],
            loading: true,
        };
        this.myRef = React.createRef();
    }

    componentDidMount() {
        this.current = this.myRef.current;
        this.populateAllData();
    }

    render() {
        const platforms = this.state.platforms.map(
            p => (<FilterItem item={p} table={this.platformFilterTable} />));
        const languages = this.state.languages.map(
            p => (<FilterItem item={p} table={this.languageFilterTable} />));
        const frameworks = this.state.frameworks.map(
            p => (<FilterItem item={p} table={this.frameworkFilterTable} />));

        const filteredPlatforms = this.state.platformsFilter.split(' ');
        const filteredLanguages = this.state.languagesFilter.split(' ');
        const filteredframeworks = this.state.frameworksFilter.split(' ');

        let filtered = this.state.items;
        if (this.state.platformsFilter !== "") {
            filtered = filtered.filter(item =>
                item.platforms.some(p => filteredPlatforms.indexOf(`${p.value}`) !== -1));
        }
        if (this.state.languagesFilter !== "") {
            filtered = filtered.filter(item =>
                item.languages.some(p => filteredLanguages.indexOf(`${p.value}`) !== -1));
        }
        if (this.state.frameworksFilter !== "") {
            filtered = filtered.filter(item =>
                item.frameworks.some(p => filteredframeworks.indexOf(`${p.value}`) !== -1));
        }

        return (
            <div className="projects section-background">
                <Subsection imageUrl='ProjectsBackground.jpg'>
                    <Grid>
                        <Row height="auto">
                            <Column width="auto">
                                <Logo size="100" variant="projects" />
                            </Column>
                            <Column width="*" verticalContentAlignment="center" horizontalContentAlignment="center">
                                <h1>Projects</h1>
                            </Column>
                        </Row>
                        <Row height="auto" horizontalContentAlignment="center">
                            <p>
                                {
                                    this.state.loading ? "Loading projects..." :
                                        "Here are some of the projects I have worked on in my free time:"
                                }
                            </p>
                        </Row>
                        {
                            this.state.loading ? '' :
                                (<><Row height="auto" horizontalContentAlignment="left">
                                    <div>Filter projects by <strong>platform</strong>.</div>
                                </Row>
                                    <Row height="auto"><div className="horizontal-scroll">
                                        {platforms}
                                    </div></Row><Row height="auto" horizontalContentAlignment="left">
                                        <div>Filter projects by <strong>language</strong>.</div>
                                    </Row>
                                    <Row height="auto"><div className="horizontal-scroll">
                                        {languages}
                                    </div></Row><Row height="auto" horizontalContentAlignment="left">
                                        <div>Filter projects by <strong>framework</strong>.</div>
                                    </Row>
                                    <Row height="auto"><div className="horizontal-scroll">
                                        {frameworks}
                                    </div></Row></>)
                        }
                        {
                            filtered.length == 0 ?
                                (<Row height="auto" horizontalContentAlignment="left">
                                    <div><strong>No results.</strong> Try adjusting the filters.</div>
                                </Row>) : ''
                        }
                    </Grid>
                </Subsection>
                {
                    Subsection.renderSubsectionsGrid(filtered,
                        this.platformFilterTable, this.languageFilterTable, this.frameworkFilterTable, 1000, "project")
                }
            </div>);
    }

    async populateAllData() {
        const state = {
            platforms: [],
            languages: [],
            frameworks: [],
            platformsFilter: "",
            languagesFilter: "",
            frameworksFilter: "",
            items: [],
            loading: true,
        };
        const projectsResponse = await fetch('siteData/projects');
        const projects = await projectsResponse.json();
        state.items = projects;
        for (const _item in state.items) {
            const item = state.items[_item];

            for (const _platform in item.platforms) {
                const platform = item.platforms[_platform];
                if (state.platforms.map(p => p.friendlyName).indexOf(platform.friendlyName) === -1)
                    state.platforms.push(platform);
            }

            for (const _language in item.languages) {
                const language = item.languages[_language];
                if (state.languages.map(p => p.friendlyName).indexOf(language.friendlyName) === -1)
                    state.languages.push(language);
            }

            for (const _framework in item.frameworks) {
                const framework = item.frameworks[_framework];
                if (state.frameworks.map(p => p.friendlyName).indexOf(framework.friendlyName) === -1)
                    state.frameworks.push(framework);
            }
        }

        state.loading = false;
        this.setState(state);
    }
}