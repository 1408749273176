import React, { Component } from 'react';
import { Subsection } from './Subsection';
import { Logo } from './Logo';
import { Grid, Column, Row } from "react-awesome-grid";
import MediaQuery from 'react-responsive';

export class EducationSection extends Component {
    constructor(props) {
        super(props);

        this.state = {
            items: [],
            loading: true,
        };
    }

    componentDidMount() {
        this.populateAllData();
    }

    render() {
        return (
            <div className="education section-background">
                <Subsection imageUrl='EducationBackground.jpg'>
                    <Grid>
                        <Row height="auto">
                            <Column width="auto">
                                <Logo size="100" variant="education" />
                            </Column>
                            <Column width="*" verticalContentAlignment="center" horizontalContentAlignment="center">
                                <h1>Education</h1>
                            </Column>
                        </Row>
                        <Row height="auto" horizontalContentAlignment="center">
                            <p>
                                {
                                    this.state.loading ? "Loading education..." :
                                        "Here is where I have attended college and what degrees I have:"
                                }
                            </p>
                        </Row>
                    </Grid>
                </Subsection>
                {
                    Subsection.renderSubsectionsGrid(this.state.items, null, null, null, 1000, "")
                }
            </div>);
}

async populateAllData() {
    const state = {
        items: [],
        loading: true,
    };
    const educationResponse = await fetch('siteData/education');
    const education = await educationResponse.json();
    state.items = education;

    state.loading = false;
    this.setState(state);
}
}