import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { FetchData } from './components/FetchData';
import { Counter } from './components/Counter';

import './custom.css'
import { MarkdownEditor } from './components/MarkdownEditor';

export default class App extends Component {
  static displayName = App.name;

  render () {
      return (
        <div>
            <Route exact path='/' component={Home} />
            <Route path='/counter' component={Counter} />
            <Route path='/fetch-data' component={FetchData} />
            <Route path='/markdown-editor' component={MarkdownEditor} />
        </div>
    );
  }
}
