export class FilterTable {
    #handlerTable;
    #stateTable;
    #updateListeners;
    constructor() {
        this.handlerTable = new Map();
        this.stateTable = new Map();
        this.updateListeners = function (filter) {
            let state = "";
            for (const [fltr, checked] of this.stateTable) {
                if (checked) {
                    state += (" " + fltr);
                }
            }

            const value = this.stateTable.get(filter);
            for (const [context, reg] of this.handlerTable) {
                if (reg.filter == filter) {
                    reg.handler.bind(context)(value);
                } else if (reg.filter === null) {
                    reg.handler.bind(context)(state);
                }
            }
        };
    }

    setFilter(filter, value) {
        this.stateTable.set(filter, value);
        this.updateListeners(filter);
    }

    getFilter(filter) {
        if (this.stateTable.has(filter))
            return this.stateTable.get(filter);
        return false;
    }

    registerChangeListener(context, filter, handler) {
        this.handlerTable.set(context, { filter: filter, handler: handler });
        if (this.stateTable.has(filter))
            handler.bind(context)(this.stateTable.get(filter));
    }

    unregisterChangeListener(context) {
        this.handlerTable.delete(context);
    }
}